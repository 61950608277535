<template>
  <v-card>
    <v-card-text>
      <div class="d-flex justify-space-between">
        <div class="d-flex">
          <!-- ปุ่มเลือกสินค้า -->
          <v-select dense outlined hide-details label="ช่วงเวลา" :items="year" class="me-2"></v-select>
          <date-picker v-model="time3" range placeholder="เฉพาะวันที่" class="picker-res"></date-picker>
        </div>
        <v-btn color="primary" v-bind="attrs" v-on="on" class="me-2 mg-res">
          <v-icon class="me-2">
            {{ icons.mdiCheck }}
          </v-icon>
          รันรีพอร์ต
        </v-btn>
      </div>
      <hr class="mt-5 mb-5" />
      <h2 class="text-center">สุขภาพของบริษัท</h2>
      <h3 class="text-center">{{ name }}</h3>
      <p class="text-center mb-0 mt-2">
        1 ม.ค. {{ new Date().getFullYear() }} - 31 ธ.ค. {{ new Date().getFullYear() }}
      </p>
      <hr class="mt-5 mb-5" />
      <!-- Chart ยอดขาย -->
      <div class="bannerText">
        <h2>ยอดขาย</h2>
        <p class="mb-0">แสดงยอดขายรายเดือนและแนวโน้มการขายแบ่งตามหน่วยธุรกิจ</p>
      </div>
      <v-card class="mx-auto text-center mt-2" color="primary" max-width="800">
        <v-card-text>
          <v-sheet color="rgba(0, 0, 0, .12)">
            <v-sparkline
              :value="value"
              color="rgba(255, 255, 255, .7)"
              height="100"
              padding="24"
              stroke-linecap="round"
              smooth
            >
              <template v-slot:label="item"> ${{ item.value }} </template>
            </v-sparkline>
          </v-sheet>
        </v-card-text>

        <v-card-text>
          <div class="font-weight-thin d-flex justify-space-around">
            <div>
              <h3 class="mb-0">ยอดขาย</h3>
              <h2>{{ sales }} THB</h2>
            </div>
            <div>
              <h3 class="mb-0">ยอดขายเฉลี่ย</h3>
              <h2>{{ averageSales }} THB</h2>
            </div>
            <div>
              <h3 class="mb-0">อัตราการเติบโตเฉลี่ย</h3>
              <h2>{{ averageGrowthRate }} %</h2>
            </div>
          </div>
        </v-card-text>
      </v-card>
      <hr class="mt-5 mb-5" />

      <!-- Chart กำไรกิจการ -->
      <div class="bannerText">
        <h2>กำไรกิจการ</h2>
        <p class="mb-0">กราฟแสดงแนวโน้มกำไร และอัตราการเติบโต</p>
      </div>
      <v-card class="mx-auto text-center mt-2" color="btnsuccess" max-width="800">
        <v-card-text>
          <v-sheet color="rgba(0, 0, 0, .12)">
            <v-sparkline
              :value="value"
              color="rgba(255, 255, 255, .7)"
              height="100"
              padding="24"
              stroke-linecap="round"
              smooth
            >
              <template v-slot:label="item"> ${{ item.value }} </template>
            </v-sparkline>
          </v-sheet>
        </v-card-text>

        <v-card-text>
          <div class="font-weight-thin d-flex justify-space-around">
            <div>
              <h3 class="mb-0">กำไรขาย</h3>
              <h2>{{ profit }} THB</h2>
            </div>
            <div>
              <h3 class="mb-0">อัตราการเติบโตเฉลี่ย</h3>
              <h2>{{ averageGrowthRate }} %</h2>
            </div>
          </div>
        </v-card-text>
      </v-card>
      <hr class="mt-5 mb-5" />

      <!-- Chrat ค่าใช้จ่าย -->
      <div class="mt-5 mb-3 bannerText">
        <h2>ค่าใช้จ่าย</h2>
        <p class="mb-0">แสดงค่าใช้จ่ายแต่ละประเภทแยกตามผังบัญชี</p>
      </div>
      <DoughnutChart />
      <hr class="mt-5 mb-5" />

      <!-- Chart สภาพคล่องเงินสด -->
      <div class="mt-5 mb-3 bannerText">
        <h2>สภาพคล่องเงินสด</h2>
        <p class="mb-0">เงินสดที่สามารถจ่ายพนักงานและเจ้าหนี้รวมถึงค่าใช้จ่ายอื่นๆ</p>
      </div>
      <LineChart />
      <hr class="mt-5 mb-5" />

      <!-- Chart ลูกค้าเลยกำหนดชำระ -->
      <div class="mt-5 mb-3 bannerText">
        <h2>ลูกค้าเลยกำหนดชำระ</h2>
        <p class="mb-0">จำนวนเงินที่ลูกค้าเลยกำหนดชำระแบ่งตามระยะเวลา</p>
      </div>
      <p class="mb-2">
        ค่าเฉลี่ยในการเรียกเก็บเงินลูกค้าอยู่ที่ <span class="font-weight-bold">{{ dayLate }}</span> วัน
      </p>
      <BarChart />
      <hr class="mt-5 mb-5" />

      <!-- Chart เจ้าหนี้เลยกำหนดชำระ -->
      <div class="mt-5 mb-3 bannerText">
        <h2>เจ้าหนี้เลยกำหนดชำระ</h2>
        <p class="mb-0">จำนวนเงินที่เจ้าหนี้เลยกำหนดชำระแบ่งตามระยะเวลา</p>
      </div>
      <p class="mb-2">
        ค่าเฉลี่ยในการจ่ายเงินซัพพลายเออร์อยู่ที่ <span class="font-weight-bold">{{ dayLateSuppler }}</span> วัน
      </p>
      <PieChart />
    </v-card-text>
  </v-card>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { mdiCheck } from '@mdi/js'
import BarChart from '../../../layouts/components/Bar.vue'
import DoughnutChart from '../../../layouts/components/Doughnut.vue'
import PieChart from '../../../layouts/components/Pie.vue'
import LineChart from '../../../layouts/components/Line.vue'

export default {
  components: { DatePicker, DoughnutChart, BarChart, PieChart, LineChart },
  setup() {
    const year = [
      'เลือกช่วงเวลา',
      'กำหนดเอง',
      'เดือนนี้',
      'ไตรมาสนี้',
      ' ปีปัจจุบัน',
      'ปีก่อนหน้า',
      'เดือนนี้ถึงวันนี้',
      'ไตรมาสนี้ถึงวันนี้',
      'ปีนี้ถึงวันนี้',
      'ปีงบประมาณปัจจุบัน',
      'ปีงบประมาณก่อนหน้า',
      '12 เดือนย้อนหลัง',
    ]

    return {
      year,
      icons: {
        mdiCheck,
      },
    }
  },
  data() {
    return {
      time1: null,
      time2: null,
      time3: null,
      dataOpenUser: false,
      name: 'Case',
      value: [423, 446, 675, 510, 590, 610, 760],
      sales: 300000,
      averageSales: 20000,
      averageGrowthRate: 20,
      profit: 10000000,
      dayLate: 25,
      dayLateSuppler: 360,
    }
  },
  methods: {
    switchOpenUser() {
      this.dataOpenUser = !this.dataOpenUser
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
.bannerText {
  background-color: #8a8d93;
  width: 30rem;
  height: 4.5rem;
  padding: 5px 10px;
  margin: 0 auto;
  border-radius: 15px;
  text-align: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.bannerText h2,
.bannerText p {
  color: #f5f5f5 !important;
}
</style>
