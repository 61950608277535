<template>
  <v-card id="account-setting-card">
    <!-- tabs -->
    <v-tabs v-model="tab" show-arrows>
      <v-tab v-for="tab in tabs" :key="tab.icon">
        <!-- <v-icon size="20" class="me-3">
          {{ tab.icon }}
        </v-icon> -->
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <CompanyHealth />
      </v-tab-item>

      <v-tab-item>
        <Statementfinancial />
      </v-tab-item>

      <v-tab-item>
        <Profit />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mdiCartOutline, mdiCartPlus, mdiMore } from '@mdi/js'
import { ref } from '@vue/composition-api'

// pages
import CompanyHealth from './Company_Health.vue'
import Statementfinancial from './Statement_financial.vue'
import Profit from './Profit.vue'

export default {
  components: {
    CompanyHealth,
    Statementfinancial,
    Profit,
  },
  setup() {
    const tab = ref('')

    // tabs
    const tabs = [
      { title: 'สุขภาพบริษัท', icon: mdiCartOutline },
      { title: 'งบแสดงฐานะการเงิน', icon: mdiCartPlus },
      { title: 'งบกำไร (ขาดทุน)', icon: mdiMore },
    ]

    return {
      tab,
      tabs,
    }
  },
  data: () => ({
    items: [
      {
        text: 'สินค้า',
        disabled: false,
        href: 'product',
      },
      {
        text: 'สต๊อก',
        disabled: true,
        href: 'stock',
      },
    ],
  }),
}
</script>

<style></style>
